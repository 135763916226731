<template>
  <div>
    <v-menu
      v-model="favoriteMenuIsVisible"
      :close-on-content-click="false"
      :disabled="store.state.profile.is_anonymous && store.state.site.features.enable_registration"
    >
      <template #activator="{ props }">
        <v-btn
          @click.stop="showFavoriteMenu(false)"
          @keyup.enter.stop="showFavoriteMenu(false)"
          v-bind="props"
          ref="favoriteMenuActivator"
          :aria-label="ariaLabel"
          :ripple="false"
          data-cy="favorites_icon"
          size="small"
          variant="text"
          icon
        >
          <v-icon
            class="c-primary fs-24"
            size="30"
          >
            {{ favorited ? fullFavoriteIcon : emptyFavoriteIcon }}
          </v-icon>
        </v-btn>
      </template>
      <v-list class="fs-16">
        <template
          v-for="list in lists"
          :key="list.id"
        >
          <v-list-item
            v-if="
              !!favorites.find(
                (favorite) => favorite.list_id === list.id && favorite.provider_id === providerId,
              )
            "
            @click="toggleFavorite(list.id)"
            aria-live="polite"
          >
            <template #prepend>
              <v-icon color="primary">
                {{ fullFavoriteIcon }}
              </v-icon>
            </template>
            <v-list-item-title
              :aria-label="'Provider saved to list ' + list.name + '. Press enter to remove.'"
            >
              {{ list.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-else
            @click="toggleFavorite(list.id)"
            aria-live="polite"
          >
            <template #prepend>
              <v-icon color="primary"> radio_button_unchecked </v-icon>
            </template>
            <v-list-item-title :aria-label="'Press enter to add to list ' + list.name">
              {{ list.name }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item
          @click="newListDialogIsVisible = true"
          data-cy="favorite-new-list"
        >
          <v-list-item-title>{{ t('+ Add to new list') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="newListDialogIsVisible"
      width="300px"
    >
      <v-card class="pa-4">
        <v-card-title>{{ t('Save to new list') }}</v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            v-model="listName"
            :label="$t('List name')"
            variant="filled"
            autofocus
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="newListDialogIsVisible = false"
            class="focus-very-visible"
            variant="text"
          >
            {{ t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="createListAndFavorite"
            :disabled="!listName"
            class="focus-very-visible"
            data-cy="favorite-list-create"
            variant="text"
            primary
          >
            {{ t('Create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const emits = defineEmits(['favorite:add', 'favorite:list', 'favorite:remove']);
const props = defineProps({
  favorites: {
    type: Array,
    default: null,
  },
  lists: {
    type: Array,
    default: null,
  },
  providerId: {
    type: String,
    default: null,
  },
});

const instance = getCurrentInstance();
const { t } = useI18n();
const router = useRouter();
const store = useStore();

const role = instance?.appContext.config.globalProperties.$role;

const listName = ref(new Date().toLocaleDateString());
const emptyFavoriteIcon = ref(role === 'specialist' ? 'add_circle_outline' : 'favorite_border');
const favoriteMenuIsVisible = ref(false);
const favoriteMenuActivator = ref(null);
const fullFavoriteIcon = ref(role === 'specialist' ? 'check_circle' : 'favorite');
const newListDialogIsVisible = ref(false);

const ariaLabel = computed(() => {
  if (favorited.value) {
    return 'Provider is a favorite';
  }
  return 'Save to favorites';
});

const favorited = computed(() => {
  if (props.favorites) {
    return !!props.favorites.find((favorite) => favorite.provider_id === props.providerId);
  }

  return false;
});

function createListAndFavorite() {
  emits('favorite:list', [listName.value, props.providerId]);
  newListDialogIsVisible.value = false;
}

function showFavoriteMenu(skipMenu) {
  if (store.state.profile.is_anonymous === true && store.state.site.features.enable_registration) {
    router.push({
      name: 'Signup',
      query: {
        searchId: router.currentRoute.value.params.searchId,
        providerId: props.providerId,
        page: router.currentRoute.value.query.page,
        action: 'favorite',
      },
    });
  } else if (skipMenu === true) {
    newListDialogIsVisible.value = true;
  } else {
    favoriteMenuIsVisible.value = true;
  }
}

function toggleFavorite(listId) {
  const favorite = props.favorites.find(
    (favorite) => favorite.provider_id === props.providerId && favorite.list_id === listId,
  );

  if (favorite) {
    emits('favorite:remove', favorite.id);
  } else {
    emits('favorite:add', [listId, props.providerId]);
  }

  favoriteMenuIsVisible.value = false;
}

watch(favoriteMenuIsVisible.value, (newVal) => {
  if (!newVal && favoriteMenuActivator.value) {
    nextTick(() => {
      favoriteMenuActivator.value.focus();
    });
  }
});

watch(newListDialogIsVisible.value, (newVal) => {
  if (!newVal) {
    nextTick(() => {
      favoriteMenuActivator.value.focus();
    });
  }
});
</script>
