<template>
  <div>
    <v-row
      v-for="(householdMember, index) in householdMembers"
      :key="householdMember.id"
    >
      <v-col>
        <v-card
          color="grey-lighten-3"
          elevation="0"
          variant="outlined"
        >
          <v-card-text class="pa-0">
            <HouseholdMemberCard
              @cancel="finishEditingHouseholdMember(householdMember)"
              @change="update($event)"
              @edit="editHouseholdMember(householdMember)"
              @removal="remove(householdMember)"
              @save="finishEditingHouseholdMember(householdMember)"
              :autofocus="autofocus"
              :can-edit="canEdit"
              :editing="isEditingHouseholdMember(householdMember)"
              :household-member-api="householdMemberApi"
              :household-member-question-register="householdMemberQuestionRegister"
              :household-member-schema="householdMemberSchema"
              :index="index"
              :is-expandable="isExpandable"
              :prevent-update="preventUpdate"
              :self-exists="selfExists"
              :spouse-exists="spouseExists"
              :staged="staged"
              :subsidy="subsidy"
              :value="householdMember"
              included-check
              outlined
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          v-if="canEdit"
          @click="$refs.addDialog?.open({})"
          :loading="processing"
          class="focus-very-visible"
          color="primary"
        >
          <v-icon start> add </v-icon>
          <span v-t="'Add another household member'" />
        </v-btn>
      </v-col>
    </v-row>

    <ContentDialog
      ref="addDialog"
      :hide-default-close="true"
      :max-width="800"
      :persistent="false"
      title="Add household member"
    >
      <template #content>
        <HouseholdMemberFields
          @cancel="() => $refs.addDialog.close()"
          @household-member-changed="() => null"
          @save="add"
          ref="householdMemberFields"
          :autofocus="autofocus"
          :dense="false"
          :edit-mode="false"
          :processing="processing"
          :readonly="false"
          :remove-disabled="true"
          :self-exists="selfExists"
          :spouse-exists="spouseExists"
        />
      </template>
    </ContentDialog>
  </div>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import HouseholdMemberCard from '@/shared/components/household-members/HouseholdMemberCard.vue';
import HouseholdMemberFields from '@/shared/components/household-members/HouseholdMemberFields.vue';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import { useStore } from 'vuex';
import { ref } from 'vue';

const store = useStore();

const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
  householdMemberApi: {
    type: Object,
    default: () => Api.parent.household_member,
  },
  householdMemberQuestionRegister: {
    type: Object,
    default: () => ({}),
  },
  householdMemberSchema: {
    type: Object,
    default: () => ({}),
  },
  isExpandable: {
    type: Boolean,
    default: false,
  },
  preventUpdate: {
    type: Boolean,
    default: false,
  },
  staged: {
    default: false,
    type: Boolean,
  },
  subsidy: {
    type: Object,
    default: null,
  },
  value: {
    default: null,
    type: Array,
  },
});

const emit = defineEmits(['change', 'isEditingHouseholdMember']);

const addDialog = ref(null);
const householdMemberFields = ref(null);
const householdMembers = ref(props.value);
const editingHouseholdMemberCards = ref([]);
const processing = ref(false);

const stagedSchemaId = computed(() => {
  return Object.values(store.state.schemas).filter(
    (schema) => schema.data_type === 'HouseholdMember',
  )[0].id;
});

const selfExists = computed(() => {
  const hhm = householdMembers.value || [];
  return hhm.filter((member) => member?.relationship?.toLowerCase() === 'self').length > 0;
});

const spouseExists = computed(() => {
  return props.subsidy?.marital_status === 'Married';
});

watch(
  () => editingHouseholdMemberCards.value,
  (newVal) => {
    if (newVal.length > 0) {
      emit('isEditingHouseholdMember', true);
    } else {
      emit('isEditingHouseholdMember', false);
    }
  },
);

async function add(newVal) {
  if (props.staged) return addStagedHouseholdMember(newVal);

  processing.value = true;
  const resp = await props.householdMemberApi.create(newVal);
  processing.value = false;
  if (resp?.status !== 201) return null;

  const newHouseholdMember = resp.data;
  newHouseholdMember.editing = true;
  householdMembers.value.push(newHouseholdMember);

  emit('change', householdMembers.value);

  householdMemberFields.value.resetDefaults();
  return addDialog.value.close();
}

function isEditingHouseholdMember(householdMember) {
  const findHouseholdMember = editingHouseholdMemberCards.value.find(
    (member) => member.id === householdMember.id,
  );
  return !!findHouseholdMember;
}

function editHouseholdMember(householdMember) {
  const { id } = householdMember;
  editingHouseholdMemberCards.value.push({ id });
}

function finishEditingHouseholdMember(householdMember) {
  const nonEditingMembers = editingHouseholdMemberCards.value.filter(
    (member) => member.id !== householdMember.id,
  );
  editingHouseholdMemberCards.value = nonEditingMembers;
}

function addStagedHouseholdMember(newVal) {
  addDialog.value.close();

  const stagedSchema = { ...newVal, schema_id: stagedSchemaId.value };
  return householdMembers.value.push(stagedSchema);
}

function remove(householdMember) {
  finishEditingHouseholdMember(householdMember);
  householdMembers.value.splice(
    householdMembers.value.findIndex((member) => member.id === householdMember.id),
    1,
  );
  emit('change', householdMembers.value);
}

function update(householdMember) {
  const index = householdMembers.value.findIndex((member) => member.id === householdMember.id);
  householdMembers.value[index] = householdMember;

  emit('change', householdMembers.value);
}
</script>

<style scoped>
.not-expandable .v-expansion-panel-title__overlay:hover {
  background-color: white;
  cursor: default;
}

.not-expandable:focus {
  outline: none;
}
</style>
