<template>
  <div>
    <v-row>
      <v-col>
        <v-row
          v-for="(key, index) in keys"
          :key="index"
        >
          <v-col
            class="d-flex flex-nowrap"
            cols="12"
          >
            <div class="pe-3">
              <v-icon
                color="secondary"
                size="24"
              >
                {{ schemaProperties.highlights.properties[key].icon }}
              </v-icon>
            </div>
            <div>
              <div
                v-t="schemaProperties.highlights.properties[key].alias"
                class="fs-16 fw-500 mb-1"
                data-testid="highlight-alias"
              />
              <translated-items
                v-if="provider.highlights?.[key] && provider.highlights[key].length > 0"
                :items="provider.highlights[key]"
                classes="fs-16 fw-400"
              />
              <div
                v-else
                class="fs-16 fw-400 c-light-black"
              >
                {{ t('Not available') }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="provider.other_programs_description">
      <v-col>
        <div class="fs-18 fw-600">
          <span v-t="otherProgramsDescriptionTitle()" />
        </div>
        <div
          v-text="provider.other_programs_description"
          class="fs-16 fw-400"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import TranslatedItems from '@/shared/components/TranslatedItems.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
  schema: {
    type: Object,
    required: true,
  },
});

const keys = computed(() => {
  function enabledKeys(key) {
    return schemaProperties.value.highlights.properties[key].enabled === true;
  }

  return Object.keys(schemaProperties.value.highlights.properties).filter(enabledKeys);
});

const schemaProperties = computed(() => props.schema.definition.properties);

function otherProgramsDescriptionTitle() {
  return schemaProperties.value.other_programs_description.alias || 'Other program information:';
}
</script>
