<template>
  <div v-if="householdMember">
    <v-row class="fs-16 d-flex flex-row align-end">
      <LabeledTextfield
        v-model="householdMember.first_name"
        :autofocus="autofocus"
        :hard-lock="hardLock"
        :schema-id="schema.id"
        cols="12"
        field="first_name"
        sm="4"
        mandatory
      />
      <LabeledTextfield
        v-model="householdMember.middle_name"
        :hard-lock="hardLock"
        :placeholder="t('Optional')"
        :schema-id="schema.id"
        cols="12"
        field="middle_name"
        sm="4"
      />
      <LabeledTextfield
        v-model="householdMember.last_name"
        :hard-lock="hardLock"
        :schema-id="schema.id"
        cols="12"
        field="last_name"
        sm="4"
        mandatory
      />
    </v-row>
    <v-row class="fs-16 d-flex flex-row align-end">
      <LabeledSimpleSelect
        v-model="householdMember.relationship"
        :hard-lock="hardLock"
        :item-props="true"
        :items="householdMemberFields(relationshipItems)"
        :schema-id="schema.id"
        cols="12"
        field="relationship"
        md="6"
        mandatory
      />
      <LabeledTextfield
        v-model="householdMember.ssn"
        :hard-lock="hardLock"
        :schema-id="schema.id"
        :type="visible ? 'text' : 'password'"
        cols="12"
        field="ssn"
        md="6"
      >
        <template #append-inner>
          <v-icon
            @click="showVisibility"
            @keyup.enter="showVisibility"
            :aria-label="ssnAriaLabel"
          >
            {{ visible ? 'visibility_off' : 'visibility' }}
          </v-icon>
        </template>
      </LabeledTextfield>

      <LabeledSimpleSelect
        v-model="householdMember.gender"
        :hard-lock="hardLock"
        :item-props="true"
        :items="householdMemberFields(schema.definition.properties.gender.enum)"
        :schema-id="schema.id"
        cols="12"
        field="gender"
        md="4"
        mandatory
      />
      <LabeledSimpleSelect
        v-model="householdMember.race"
        :hard-lock="hardLock"
        :item-props="true"
        :items="householdMemberFields(schema.definition.properties.race.items.enum)"
        :schema-id="schema.id"
        cols="12"
        field="race"
        md="4"
        multiple
      />
      <LabeledSimpleSelect
        v-model="householdMember.ethnicity"
        :hard-lock="hardLock"
        :item-props="true"
        :items="householdMemberFields(schema.definition.properties.ethnicity.enum)"
        :schema-id="schema.id"
        cols="12"
        field="ethnicity"
        md="4"
        mandatory
      />
    </v-row>
    <v-row class="fs-16 d-flex flex-row align-end">
      <LabeledDateOfBirth
        v-model="householdMember.dob"
        :hard-lock="hardLock"
        :schema-id="schema.id"
        cols="12"
        field="dob"
        message="Date of birth"
        mandatory
      />
    </v-row>
    <v-row class="fs-16" />
    <v-row v-if="!hardLock">
      <v-col
        class="d-flex justify-start"
        cols="6"
      >
        <v-btn
          @click="cancel"
          :loading="processing"
          :ripple="false"
          class="focus-very-visible me-3"
          color="primary"
          size="large"
          variant="outlined"
        >
          {{ t('Cancel') }}
        </v-btn>
      </v-col>
      <v-col
        class="d-flex justify-end"
        cols="6"
      >
        <v-btn
          v-if="!removeDisabled"
          @click="remove"
          :loading="processing"
          :ripple="false"
          class="focus-very-visible me-3"
          color="red"
          size="large"
          variant="flat"
        >
          {{ t('Remove') }}
        </v-btn>
        <v-btn
          @click="save"
          :loading="processing"
          :ripple="false"
          class="focus-very-visible me-3"
          color="primary"
          size="large"
        >
          {{ t('Save') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { householdMemberDefaults } from '@/shared/components/household-members/HouseholdMember';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['save', 'cancel', 'householdMemberChanged', 'remove']);
const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  hardLock: {
    type: Boolean,
    default: false,
  },
  householdMemberValues: {
    type: Object,
    default() {
      return householdMemberDefaults;
    },
  },
  editMode: {
    type: Boolean,
    default: false,
  },
  removeDisabled: {
    type: Boolean,
    default: false,
  },
  selfExists: {
    type: Boolean,
    default: false,
  },
  spouseExists: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const store = useStore();

const householdMember = ref(null);
const visible = ref(false);

defineExpose({ resetDefaults });

const schema = computed(() => {
  return Object.values(store.state.schemas).find((val) => val.data_type === 'HouseholdMember');
});

const ssnAriaLabel = computed(() => {
  return visible.value ? t('Hide SSN') : t('Show SSN');
});

const relationshipItems = computed(() => {
  const exclude = [];

  if (props.selfExists) {
    exclude.push('self');
  }

  if (!props.spouseExists) {
    exclude.push('spouse');
  }

  const enumItems = schema.value.definition.properties.relationship.enum || [];

  return enumItems.filter((item) => {
    return !exclude.includes(item?.toLowerCase());
  });
});

function cancel() {
  emit('cancel');
  resetDefaults();
}

function getCopiedValue() {
  return JSON.parse(JSON.stringify(props.householdMemberValues));
}

function householdMemberFields(fields) {
  return (fields || []).map((fieldName) => {
    return {
      text: t(fieldName),
      value: fieldName,
    };
  });
}

function remove() {
  emit('remove', householdMember.value);
}

function resetDefaults() {
  if (!props.editMode) {
    householdMember.value = { ...householdMemberDefaults };
  }
}

function save() {
  emit('save', householdMember.value);
}

function showVisibility() {
  visible.value = !visible.value;
}

watch(
  householdMember,
  (newVal) => {
    emit('householdMemberChanged', newVal);
  },
  { deep: true },
);

onMounted(() => {
  householdMember.value = getCopiedValue();
});
</script>
